
import { computed, defineComponent, ref } from "vue";
import { useCpmiSubmissionFormStore } from "@/store/pinia/gws-global-feature/cpmi/cpmi-submission/useCpmiSubmissionFormStore";
import { useCpmiSubmissionListStore } from "@/store/pinia/gws-global-feature/cpmi/cpmi-submission/useCpmiSubmissionListStore";
import { swalAlertInfo } from "@/core/helpers/sweet-alert";

const listStore = useCpmiSubmissionListStore();
const formStore = useCpmiSubmissionFormStore();

export default defineComponent({
  name: "CpmiStatusTimeline",
  setup(props, { emit }) {
    const status = ref([
      {
        value: "APPLICATION_INFORMATION",
        label: "Informasi Permohonan",
      },
      {
        value: "PERSONAL_DATA",
        label: "Data Pribadi",
      },
      {
        value: "RELATIONSHIP",
        label: "Hubungan Keluarga",
      },
      {
        value: "EDUCATION",
        label: "Pendidikan",
      },
      {
        value: "CRIMINAL_CASES",
        label: "Perkara Pidana",
      },
      {
        value: "VIOLATION",
        label: "Pelanggaran",
      },
      {
        value: "OTHER_INFORMATION",
        label: "Informasi Lainnya",
      },
      {
        value: "PHYSICAL_CHARACTERISTICS",
        label: "Ciri Fisik",
      },
      {
        value: "ATTACHMENT",
        label: "Lampiran",
      },
      {
        value: "APPLICATION_PROCESS",
        label: "Proses Permohonan SKCK",
      },
    ]);

    const formData = computed(() => {
      return formStore.submissionData;
    });

    const hoveredStatus = ref("");

    const isHovered = (statusValue: string) => {
      return hoveredStatus.value === statusValue;
    };

    const handleMouseOver = (statusValue: string) => {
      hoveredStatus.value = statusValue;
    };

    const handleMouseOut = () => {
      hoveredStatus.value = "";
    };

    const isActive = (statusValue: string) => {
      return props.activeStatus === statusValue;
    };

    const handleChangeStatus = (stepValue: string) => {
      const skckId = formData.value.id;
      if(skckId){
        formStore.updateStep(stepValue, skckId).then((d) => {
          window.location.reload();
        });
      } else {
        swalAlertInfo("Proses belum mencapai step ini.", "Close");
      }
    };

    return {
      status,
      isHovered,
      handleMouseOver,
      handleMouseOut,
      handleChangeStatus,
      isActive,
    };
  },
  props: {
    activeStatus: {
      type: String,
      default: "",
      required: true,
    },
  },
});
