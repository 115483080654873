import ApiService from "@/core/services/ApiService";
import { SubmissionData } from "@/core/types/gws-agent/submission/SubmissionData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { Option } from "@/core/types/misc/Option";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MasterCpmiStatus } from "@/core/config/MasterCpmiStatus";
import { SubmissionSkckStatusEnum, SubmissionSkckStepEnum } from "@/store/enums/StatusEnums";

export const useCpmiSubmissionListStore = defineStore({
  id: "cpmiSubmissionStore",
  state: () => {
    return {
      stateLoading: false,
      stateSubmissionData: {} as SubmissionData,
      stateCompanyOption: [] as Option[],
      stateSyarikahOption: [] as Option[],
      stateAgentOption: [] as Option[],
      stateJobFairOption: [] as Option[],
      stateMasterCpmiStatus: SubmissionSkckStatusEnum,
      stateMasterCpmiStep: SubmissionSkckStepEnum,
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    submissionData(state) {
      return state.stateSubmissionData;
    },
    companyOption(state) {
      return state.stateCompanyOption;
    },
    agentOption(state) {
      return state.stateAgentOption;
    },
    syarikahOption(state) {
      return state.stateSyarikahOption;
    },
    jobFairs(state) {
      return state.stateJobFairOption;
    },
    masterStatus(state) {
      return state.stateMasterCpmiStatus;
    },
    masterStep(state) {
      return state.stateMasterCpmiStep;
    },
  },
  actions: {
    async getLookupCompany() {
      this.stateCompanyOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `companies?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.p3mi;
        listCompany.forEach((el) => {
          this.stateCompanyOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupSyarikah() {
      this.stateSyarikahOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `syarikah?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.syarikah;
        listCompany.forEach((el) => {
          this.stateSyarikahOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupAgent() {
      this.stateAgentOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `agents?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.agent_p3mi;
        listCompany.forEach((el) => {
          this.stateAgentOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupJobfair() {
      this.stateJobFairOption = [];
      const params = {
        page: "1",
        limit: "999",
        dir: "DESC",
        sort: "created_at",
      };
      try {
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `job-fairs?${new URLSearchParams(params).toString()}`
        );
        const listCompany = response.data.data.job_fair;
        listCompany.forEach((el) => {
          this.stateJobFairOption.push({
            label: el.name,
            value: el.id,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
