<template>
  <div class="card p-4">
    <div class="row">
      <template v-for="(st, i) in status" :key="i">
        <div class="d-flex align-items-center gap-4">
          <!-- Blue circle component -->
          <div v-if="isActive(st.value)" class="blue-circle"></div>
          <div v-if="!isActive(st.value)" class="blue-circle-inactive">
            <div v-if="!isActive(st.value)" class="blue-circle-inactive"></div>
          </div>
          <div>
            <span
              :class="{
                'fw-bolder': isActive(st.value) || isHovered(st.value),
              }"
              @click="() => handleChangeStatus(st.value)"
              @mouseover="handleMouseOver(st.value)"
              @mouseout="handleMouseOut"
              :style="{ color: isActive(st.value) || isHovered(st.value) ? '#00119E' : '' }"
              >{{ st.label }}</span
            >
          </div>
        </div>
        <div v-if="i !== status.length - 1" class="d-flex align-items-center">
          <div v-if="isActive(st.value)" class="vl"></div>
          <div v-if="!isActive(st.value)" class="vl-inactive"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useCpmiSubmissionFormStore } from "@/store/pinia/gws-global-feature/cpmi/cpmi-submission/useCpmiSubmissionFormStore";
import { useCpmiSubmissionListStore } from "@/store/pinia/gws-global-feature/cpmi/cpmi-submission/useCpmiSubmissionListStore";
import { swalAlertInfo } from "@/core/helpers/sweet-alert";

const listStore = useCpmiSubmissionListStore();
const formStore = useCpmiSubmissionFormStore();

export default defineComponent({
  name: "CpmiStatusTimeline",
  setup(props, { emit }) {
    const status = ref([
      {
        value: "APPLICATION_INFORMATION",
        label: "Informasi Permohonan",
      },
      {
        value: "PERSONAL_DATA",
        label: "Data Pribadi",
      },
      {
        value: "RELATIONSHIP",
        label: "Hubungan Keluarga",
      },
      {
        value: "EDUCATION",
        label: "Pendidikan",
      },
      {
        value: "CRIMINAL_CASES",
        label: "Perkara Pidana",
      },
      {
        value: "VIOLATION",
        label: "Pelanggaran",
      },
      {
        value: "OTHER_INFORMATION",
        label: "Informasi Lainnya",
      },
      {
        value: "PHYSICAL_CHARACTERISTICS",
        label: "Ciri Fisik",
      },
      {
        value: "ATTACHMENT",
        label: "Lampiran",
      },
      {
        value: "APPLICATION_PROCESS",
        label: "Proses Permohonan SKCK",
      },
    ]);

    const formData = computed(() => {
      return formStore.submissionData;
    });

    const hoveredStatus = ref("");

    const isHovered = (statusValue: string) => {
      return hoveredStatus.value === statusValue;
    };

    const handleMouseOver = (statusValue: string) => {
      hoveredStatus.value = statusValue;
    };

    const handleMouseOut = () => {
      hoveredStatus.value = "";
    };

    const isActive = (statusValue: string) => {
      return props.activeStatus === statusValue;
    };

    const handleChangeStatus = (stepValue: string) => {
      const skckId = formData.value.id;
      if(skckId){
        formStore.updateStep(stepValue, skckId).then((d) => {
          window.location.reload();
        });
      } else {
        swalAlertInfo("Proses belum mencapai step ini.", "Close");
      }
    };

    return {
      status,
      isHovered,
      handleMouseOver,
      handleMouseOut,
      handleChangeStatus,
      isActive,
    };
  },
  props: {
    activeStatus: {
      type: String,
      default: "",
      required: true,
    },
  },
});
</script>

<style scoped>
.blue-circle {
  width: 12px;
  height: 12px;
  background-color: #00119e;
  border-radius: 50%;
}
.donut {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1.5px solid blue; /* Outline color and thickness */
  /* position: relative; */
  /* align-items: center; */
}

.vl {
  border-right: 2px solid #00119e;
  height: 30px;
  margin-left: 4.5px;
}
.vl-inactive {
  border-right: 2px solid lightblue;
  height: 30px;
  margin-left: 4.5px;
}

.blue-circle-inactive {
  background-color: lightblue;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  align-self: center;
}
</style>
